import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../breadCrumb";
import { PhrasesContext } from "../../context/phrasesContext";
import { getServiceDetails } from "../../services/Common";
import { useParams } from "react-router-dom";
import LargeTitle from "../case-tracking/LargeTitle";
import { USDollar } from "../../utils/helpers";
import PaymentElectronic from "./PaymentElectronic";
import PaymentBank from "./PaymentBank";
import SharedModal from "../happiness/SharedModal";
import ConfirmPayment from "../paymentPopup/ConfirmPayment";
import FailedPayment from "../paymentPopup/FailedPayment";
import SuccessPayment from "../paymentPopup/SuccessPayment";

const PaymentDiffrentMethod = () => {
  const { caseid, serviceid } = useParams();
  const [lang, setLang] = useState("");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [service, setService] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [openModalFail, setOpenModalFail] = useState(false);
  const [openModalSucc, setOpenModalSucc] = useState(false);
  const [ppidValue, setPPidValue] = useState("");
  const [isPaid, setIsPaid] = useState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log("urlParamsurlParams", urlParams);
  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getServiceDetailsService(currentLang);
  }, []);

  const getServiceDetailsService = (currentLang) => {
    return getServiceDetails(caseid, serviceid, currentLang).then((res) => {
      setService(res);
    });
  };

  useEffect(() => {
    const codeValue = urlParams.get("StatusCode");
    const message = urlParams.get("message");

    if (codeValue === "100" && message) {
      console.log("PaymentSuccess");
      setOpenModalSucc(true);
      setPPidValue(`${urlParams.get("PPId")}`);
      setIsPaid(true);
    } else if (codeValue !== "100" && message) {
      console.log("PaymentFalied");
      setOpenModalFail(true);
    }
  }, []);

  // useEffect(() => {
  //   if (isPaid) {
  //     UpdatePaymentStatus(serviceid, isPaid, ppidValue);
  //   }
  // }, [isPaid]);

  return (
    <div className="flex flex-col w-full py-4 max-w-[1140px] px-[16px] lg:px-[0px]">
      <Breadcrumb
        title={lang === "en" ? enPhrase["lblMycases"] : arPhrase["lblMycases"]}
        subtitle1={`${
          lang === "en" ? `${service?.caseNumber}` : `${service?.caseNumber}`
        }`}
        subtitle1Href={`${
          service?.caseId === 0
            ? "/case-overview"
            : `/case-details/${service?.caseId}`
        }`}
        last={`${
          lang === "en"
            ? `${enPhrase["lblRequest"]} ${service?.id}`
            : `${service?.id} ${arPhrase["lblRequest"]}`
        }`}
        lastHref={`${`/order-details/${service?.caseId}/${service?.id}`}`}
        lastSecond={
          lang === "en"
            ? enPhrase["lblPaymentMethodBreadcrumb"]
            : arPhrase["lblPaymentMethodBreadcrumb"]
        }
        lastSecondHref={`/payment-method/${service?.caseId}/${service?.id}`}
      />

      <div className="flex flex-col xxsm:flex-row justify-between items-center gap-2 mb-8">
        <LargeTitle
          title={
            lang === "en"
              ? enPhrase["lblChosePaymentMethod"]
              : arPhrase["lblChosePaymentMethod"]
          }
          nullmargin
        />
      </div>

      <div className="flex items-center justify-between w-full border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] h-[72px] mb-[24px]">
        <p className="text-[20px] text-[#0E0D0C] leading-[24px] font-Almarai font-bold">
          {lang === "en"
            ? enPhrase["lblTotalAmount"]
            : arPhrase["lblTotalAmount"]}
        </p>
        <div className="leading-normal flex items-center gap-[5px] font-medium	">
          <span className="text-[#A98031] font-Almarai text-[20px] ">
            {USDollar.format(service?.serviceValue)}
          </span>

          <span className="text-[20px] font-Almarai text-[#A98031]">
            {lang === "en" ? "AED" : "درهم"}
          </span>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-between gap-[24px]">
        <PaymentElectronic
          service={service}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
          setOpenModal={setOpenModal}
        />
        <PaymentBank
          service={service}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
        />
      </div>

      {openModal && (
        <SharedModal isPaymentConfirm setOpenModal={setOpenModal}>
          <ConfirmPayment
            claimedValue={USDollar.format(service?.serviceValue)}
            setOpenModal={setOpenModal}
            service={service}
            caseid={serviceid}
            serviceid={caseid}
            setOpenModalFail={setOpenModalFail}
            setOpenModalSucc={setOpenModalSucc}
          />
          {/* // const html = <SuccessPayment />;
    // const html = <FailedPayment />; */}
        </SharedModal>
      )}
      {openModalFail && (
        <SharedModal isFailedPayment setOpenModal={setOpenModalFail}>
          <FailedPayment
            setOpenModal={setOpenModalFail}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
            serviceid={service?.id}
            caseId={service?.caseId}
          />
        </SharedModal>
      )}
      {openModalSucc && (
        <SharedModal isSuccessPayment setOpenModal={setOpenModalSucc}>
          <SuccessPayment
            setOpenModal={setOpenModalSucc}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
            serviceid={serviceid}
            ppidValue={ppidValue}
            isPaid={isPaid}
          />
        </SharedModal>
      )}
    </div>
  );
};

export default PaymentDiffrentMethod;
