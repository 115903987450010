import { useEffect } from "react";

const Map = (props) => {
  const { centerPoint, zoom, Markers, mapH, mapW, borderRadius } = props;

  useEffect(() => {
    function initMap() {
      const google = window.google;

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: zoom,
        center: centerPoint,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        mapTypeControl: true, //hide map type.

        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_CENTER,
        },
        scaleControl: false, //disable zoom by scale.
        scrollwheel: false, //disable zoom on mouse.
        draggable: false,
        keyboardShortcuts: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: "greedy",
        mapTypeId: "hybrid",
      });

      google.maps.InfoWindow.prototype.set = function (key, val) {
        if (key === "map") {
          return;
        }
      };

      Markers?.map((item) => {
        const mark = new google.maps.Marker({
          position: new google.maps.LatLng(item?.lat, item?.lng),

          icon: {
            url: item?.icon,
            scaledSize: new window.google.maps.Size(item?.iconW, item?.iconH),
            scale: item?.iconScale,
          },
          map: map,
        });
        return;
      });
    }

    window.initMap = initMap;
  }, [Markers]);

  useEffect(() => {
    const externalScript = document.createElement("script");
    externalScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCppCsio0715mEWqXlcXcJzWeyf3m5nir8&callback=initMap&v=weekly";
    externalScript.async = true;
    document.head.append(externalScript);
    return () => {
      externalScript.remove();
    };
  }, [Markers]);

  return (
    <>
      <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
      {/* <script
        async
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCppCsio0715mEWqXlcXcJzWeyf3m5nir8&callback=initMap&v=weekly"
      ></script> */}

      <div style={{ height: mapH, width: mapW }}>
        <div
          id="map"
          className="w-full h-full"
          style={{ borderRadius: borderRadius }}
        ></div>
      </div>
    </>
  );
};

export default Map;
