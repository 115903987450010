import React from "react";

import { LiveChatWidget } from "@livechat/widget-react";
import { useState } from "react";

import ServicesSlide from "../components/ServicesSlide";
import ServicesSlideItems from "../components/ServicesSlideItems";

const HomeBanner = ({ lang, enPhrase, arPhrase, services }) => {
  const [chatBtn, setChatBtn] = useState(true);

  const ChatHandler = () => {
    if (chatBtn) {
      setChatBtn(false);
    } else {
      setChatBtn(true);
    }
  };

  const scrollHandler = () => {
    window.scrollTo(document.body.scrollHeight, 700);
  };

  const chatSettings = {
    language: "ar", // Set language to Arabic
  };

  // const openPDF = () => {
  //   // Replace 'sample.pdf' with the actual path to your PDF file
  //   window.open("/assets/img/01.pdf", "_blank");
  //   console.log("openPDFopenPDF");
  // };

  const pdf =()=>{
    console.log("loading","loading")
  }

  return (
    <div
      id="home"
      className="max-w-full overflow-hidden bg-white wow fadeInUp bg-hero-pattern bg-no-repeat bg-cover bg-center	 pt-[64px] pb-[24px]  md:pt-[120px] md:pb-[120px] h-[741px] relative ]"
    >
      <div className="absolute top-0 bg-background-home w-full h-[741px]"></div>
      <section className=" relative">
        <div
          className={`text-center drop-shadow-[0_2px_4px_rgba(0,0,0,0.75)] ${
            lang === "en" ? "max-w-[612px]" : "max-w-[424px]"
          } m-auto`}
        >
          <h2 className="text-[30px] md:text-[44px] font-Almarai font-bold text-[#fff] leading-[1.37]">
            {lang === "en"
              ? enPhrase["lbl_banner_title"]
              : arPhrase["lbl_banner_title"]}
          </h2>
        </div>
        <div className="text-center pl-[20px] md:pl-[0px] drop-shadow-[0_2px_4px_rgba(0,0,0,0.75)]  pr-[20px] md:pr-[0px]">
          <p className="text-[#fff] max-w-[424px] text-[16px] md:text-[18px] font-Almarai  text-center m-auto">
            {lang === "en"
              ? enPhrase["lblEnforcementProcedures"]
              : arPhrase["lblEnforcementProcedures"]}
          </p>
        </div>
      </section>
      <div
        id="services"
        className={`mt-[120px]  relative ${
          lang === "en" ? "md:mt-[65px]" : "md:mt-[107px]"
        }  md:px-[0px]`}
      >
        {window && window.innerWidth <= 1024 ? (
          <ServicesSlide
            services={services}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
          />
        ) : (
          <ServicesSlideItems
            services={services}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
          />
        )}
        {/* <ServicesSlide
          services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
        /> */}
        {/* <ServicesSlideItems services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase} /> */}
        {/* <ServicesSlideLatest
          services={services}
          lang={lang}
          arPhrase={arPhrase}
          enPhrase={enPhrase}
        /> */}
      </div>
     

      <div
        onClick={scrollHandler}
        className="absolute bottom-[15px] left-[49%] cursor-pointer hidden md:block w-[32px] h-[32px]"
      >
        <img src="/assets/img/home/Animation -mouse.gif" alt="scroll" />
      </div>

      

      {chatBtn === true ? (
        <LiveChatWidget
          chatSettings={chatSettings}
          license="16802304"
          visibility="minimized"
        />
      ) : (
        <LiveChatWidget
          chatSettings={chatSettings}
          license="16802304"
          visibility="maximized"
        />
      )}
    </div>
  );
};

export default HomeBanner;
